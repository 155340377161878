import IframeReceiver from "./IframeReceiver";

function App() {
  return (
    <div className="App">
      <IframeReceiver />
    </div>
  );
}

export default App;
