import { useState, useEffect } from "react";

function createMarkup(html) {
  return {__html: html};
}

export default function IframeReceiver() {
    const [embedCode, setEmbedCode] = useState('');

    useEffect(() => {
        let iframeConfig = null;

        try {
            iframeConfig = decodeURIComponent(window.name);
            iframeConfig = JSON.parse(iframeConfig);
        } catch (error) {
            console.log("[iframe] error decoding iframe config");
        }

        // add event listener
        window.addEventListener('message', messageReceivedFromPage.bind(this));

        if (iframeConfig) {
            setEmbedCode(iframeConfig.embedCode);
        }
    }, []);

    function messageReceivedFromPage() {}

    return (
        <div
            dangerouslySetInnerHTML={createMarkup(embedCode)}
        />
    );
}
